<template>
	<loginBase class="" minheight="620px">
		<div v-if="page!='qr'">
			<!-- <div @click="pageChangeFun('qr')" class="imgC"></div>
			<div class="imgT"></div> -->
			<div class="title">
				<span class="title_active">忘记密码</span>
			</div>
			<el-form class="loginForm" :model="codePost" :hide-required-asterisk="true" :rules="codeRules" ref="codeForm" label-width="80px" label-position="top">
			  <el-form-item class="mobileNumer" label="手机号码" prop="mobile">
					<el-input  placeholder="请输入手机号码" v-model="codePost.mobile">
					<template slot="prefix">
						<el-dropdown  @command="mobileNumFun" trigger="click">
							  <span class="mobileNumer_line" style="cursor: pointer;">
								<span style="width: 30px;display: inline-block; ">{{mobileNum}}</span> <i class="el-icon-caret-bottom"></i>
							  </span>
							  <el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="item.val" v-for="(item,index) in mobileNumList">{{item.name}} {{item.val}}</el-dropdown-item>
							  </el-dropdown-menu>
							</el-dropdown>
					</template>
					</el-input>
			  </el-form-item>
			  <el-form-item class="getcode" label="验证码" prop="code">
					<el-input  v-model="codePost.code"  placeholder="请输入验证码">
						<template slot="suffix">
							<span @click="getCodeFun" class="getcode_num" :style="{'color':(codePost.mobile.length==11)?'#006aff':'#b6babf'}">{{codetitle}}</span>
						</template>
					</el-input>
			  </el-form-item>
			  <el-form-item class="getcode" label="设置密码" prop="password">
				<el-input type="password" v-model="codePost.password"  placeholder="8-16位数，含字母和数字">
				</el-input>
			  </el-form-item>
			  <el-form-item class="getcode" label="重复密码" prop="password">
				<el-input type="password" v-model="codePost.surepassword"  placeholder="8-16位数，含字母和数字">
				</el-input>
			  </el-form-item>
			  <el-form-item label="" style="margin-top: -10px;">
				<div class="page_to">
					已有账号？<router-link to="login">登录</router-link>
				</div> 
			  </el-form-item>
			  <el-form-item style="position: absolute;bottom: 15px;width: 340px;">
				<el-button @click="sureForget" type="primary" style="width: 100%;height: 50px;font-size: 16px;">确认修改</el-button>
				<!-- <p class="agrement">登录即表示同意<a href="">《注册协议》</a><a href="">《个人信息处理规则》</a></p> -->
			  </el-form-item>
			</el-form>
			
		</div>
		
		<div v-else>
			<div @click="pageChangeFun('input')" class="imgCQ"></div>
			<div class="imgTQ"></div>
			<div class="title">
				<span class="title_active">微信扫码注册</span>
			</div>
			<div class="qr_box">
				<div class="qr_box_img"></div>
				<div class="qr_box_flex">
					<img src="./img/wxicon.png" alt="" srcset="" />
					微信扫一扫
				</div>
			</div>
			<p class="agrement" style="margin-top: 35px;">登录即表示同意<a href="">《注册协议》</a><a href="">《个人信息处理规则》</a></p>
		</div>
	</loginBase>
</template>

<script>
	import { eltips } from "@/util/util";
	import loginBase from './components/base.vue'
	export default {
		components:{loginBase},
		name: 'adminlogin',
		data() {
			return {
				page:'input',
				type:'code',
				mobileNum:'+86',
				mobileNumList:[
					{name:'中国大陆',val:'+86'},
					{name:'中国香港',val:'+852'}
				],
				
				codeInter:null,
				codeTime:60,
				codetitle:'获取验证码',
				codePost:{
					mobile:'',
					code:'',
					password:'',
					surepassword:''
				},
				codeRules: {
					mobile: [
						{ required: true, message: '请输入手机号码', trigger: 'blur' },
					],
					code: [
						{ required: true, message: '请输入验证码', trigger: 'blur' },
					],
					password: [
						{ required: true, message: '请输入新密码', trigger: 'blur' },
					],
					surepassword: [
						{ required: true, message: '请确认新密码', trigger: 'blur' },
					]
				}
			}
		},
		created() {
			sessionStorage.setItem('shopid', 0)
		},
		methods:{
			pageChangeFun(e){
				this.page = e
			},
			getCodeFun(){
				let _this = this
				if(this.codePost.mobile.length<0){
					eltips('手机号不能为空', "error");
					return
				}
				if(this.codeTime!=60){
					return
				}
				_this.axios.post('/api/common/send-sms',{
					  mobile:this.codePost.mobile,
					  type:2
				}).then((res) => {
					if(res.code == 200){
						_this.codeInter = setInterval(function(){
							_this.codeTime--;
							_this.codetitle = _this.codeTime + 'S'
							if(_this.codeTime<=1){
								clearInterval(_this.codeInter)
								_this.codeInter = null
								_this.codeTime = 60
								_this.codetitle = '获取验证码'
							}
						},1000)
					}else{
						_this.codetitle = '发送验证码'
						_this.codeTime = 60
						_this.codeInter = null
						eltips(res.msg, "error");
					}
				})
				
			},
			sureForget(){
				let _this = this
				if(_this.codePost.password.length<=0){
					eltips('密码不能为空', "error");
					return false
				}
				if(_this.codePost.password !== _this.codePost.surepassword ){
					eltips('两次密码输入不一致', "error");
					return false
				}
				
				_this.axios.post('/api/shop/basic/find-pwd',_this.codePost).then((res) => {
					if(res.code == 200){
						eltips(res.msg, "success");
						_this.$router.replace({
						  path: '/login'
						});
					}else{
						eltips(res.msg, "error");
					}
				})
			},
			tabChange(e){
				this.type = e
				// this.$nextTick(() => {
				// 	this.$refs.codeForm.clearValidate();
				// 	this.$refs.pwdForm.clearValidate();
				// })
			},
			mobileNumFun(e){
				this.mobileNum = e
				console.log(e)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.imgC{
		position: absolute;
		top: 0;
		right: 0;
		width: 101px;
		height: 101px;
		display: block;
		background: url(./img/qrcode2.png) no-repeat 50%;
		background-size: 90px;
		cursor: pointer;
		z-index: 9;
	}
	.imgC:hover{
		width: 101px;
		height: 101px;
		background-image: url(./img/qrcode_hover2.png)!important;
		transition: all .4s ease;
		background-size: 90px;
	}
	.imgT{
		position: absolute;
		right: 0;
		top: 0;
		width: 90px;
		height: 90px;
		border-radius: 0 10px 0 0;
		border-color: #f2f7ff #f2f7ff #fff #fff;
		border-style: solid;
		border-width: 50px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.title{
		span{
			font-size: 20px;
			color: #b6babf;
			cursor: pointer;
			font-weight: 400;
			-webkit-transition: color .2s linear;
			transition: color .2s linear;
		}
		&_active{
			color: #1e2226 !important;
			font-weight: 600 !important;
		}
		strong{
			width: 1px;
			height: 16px;
			background: #e1e2e6;
			margin: 0 12px;
			display: inline-block;
		}
	}
	.loginForm{
		margin-top: 30px;
	}
	.loginForm ::v-deep .el-form-item__label{
		padding-bottom: 0;
		font-weight: 600;
		color: #1f2026;
		font-size: 14px;
	}
	.page_to{
		display: flex;
		align-items: center;
		// justify-content: space-between;
		a{
			color: #006aff;
		}
	}
	.agrement{
		display: flex;
		color: #8a9099;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-top: 10px;
		height: 19px;
		line-height: 20px;
		font-size: 12px;
		a{
			color: #1e2226;
		}
	}
	
	.mobileNumer{
		&_line{
			height: 40px;
			line-height: 40px;
			border-right: 1px solid #ededed;
			padding-right: 8px;
			padding-left: 10px;
		}
	}
	
	.mobileNumer ::v-deep .el-input-group__prepend{
		background-color: #fff;
		border-right: 0 !important;
	}
	.mobileNumer ::v-deep .el-input__inner{
		padding-left: 80px;
	}
	
	.getcode ::v-deep .el-input__inner{
		padding-right: 90px;
	}
	.getcode_num{
		width: 80px;
		height: 40px;
		line-height: 40px;
		padding-right: 10px;
		text-align: center;
		cursor: pointer;
		color: #b6babf;
		font-size: 14px;
	}
	
	.imgCQ{
		position: absolute;
		top: 0;
		right: 0;
		width: 101px;
		height: 101px;
		display: block;
		background: url(./img/account_2.png) no-repeat 50%;
		background-size: 90px;
		cursor: pointer;
		z-index: 9;
	}
	.imgCQ:hover{
		width: 101px;
		height: 101px;
		background-image: url(./img/account_hover2.png)!important;
		transition: all .4s ease;
		background-size: 90px;
	}
	.imgTQ{
		position: absolute;
		right: 0;
		top: 0;
		width: 90px;
		height: 90px;
		border-radius: 0 10px 0 0;
		border-color: #f2f7ff #f2f7ff #fff #fff;
		border-style: solid;
		border-width: 50px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.qr_box{
		width: 240px;
		height: 284px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border: 1px solid #f0f2f5;
		border-radius: 6px;
		padding: 12px;
		margin:35px auto 28px auto;
		position: relative;
		-webkit-transition: margin .5s ease;
		transition: margin .5s ease;
		&_img{
			width: 216px;
			height: 216px;
			border-radius:6px;
			background-color: #8a9099;
			
		}
		&_flex{
			display: flex;
			align-items: center;
			justify-content: center;
			color: #8a9099;
			font-size: 14px;
			margin-top: 12px;
			img{
				width: 20px;
				height: 20px;
				margin-right: 4px;
			}
		}
	}
</style>